import styled from 'styled-components'

import { Container } from 'common/UI'
import { SuccessRateDynamicStoryblok } from 'common/types'

import { ClinicSectionHeader } from './ClinicSectionHeader'
import { Editable } from './Editable'
import { Graph } from './Graph/Graph'

type Props = {
  block: SuccessRateDynamicStoryblok
}

export const SuccessRateDynamic = ({ block, ...props }: Props) => {
  return (
    <>
      {block.header?.[0] && (
        <Editable block={block.header?.[0]}>
          <ClinicSectionHeader block={block.header?.[0]} />{' '}
        </Editable>
      )}
      <Container as="section" {...props}>
        <Wrapper hasTop={!!block.tag || !!block.title || !!block.description}>
          {block.graph && <Graph graph={block.graph} />}
        </Wrapper>
      </Container>
    </>
  )
}

const Wrapper = styled.div<{ hasTop?: boolean }>`
  padding-bottom: 2.5rem;

  ${({ theme }) => theme.media.lg} {
    padding-bottom: 3.75rem;
  }
`
